import clsx from "clsx";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import * as React from "react";

export type ImageCardProps = {
  categories: string;
  image: IGatsbyImageData;
  alt: string;
  heading: string;
};

export function ImageCard(props: ImageCardProps) {
  return (
    <article
      className={clsx(
        "grid grid-cols-1 grid-rows-1",
        "h-full overflow-hidden",
        "group transition-all"
      )}
    >
      {props.image && props.alt && (
        <div
          className={clsx("overflow-hidden col-start-1 row-start-1 relative")}
        >
          <GatsbyImage
            image={props.image}
            alt={props.alt}
            className={clsx(
              "w-full object-cover aspect-square sm:aspect-auto",
              "group-hover:scale-105 transition-all ease-in"
            )}
          />
        </div>
      )}
      <div className="grid place-content-center p-6 col-start-1 row-start-1 z-10">
        <div className="font-condensed text-center bg-neutral-100 p-4">
          <h2 className="text-3xl md:text-xl lg:text-2xl text-neutral-700 font-bold uppercase mb-2">
            {props.heading}
          </h2>
          <p className="text-base lg:text-lg">{props.categories}</p>
        </div>
      </div>
    </article>
  );
}
