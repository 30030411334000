import clsx from "clsx";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function PresentationCard() {
  return (
    <article
      className={clsx(
        "w-full",
        "h-full",
        "grid justify-items-center",
        "md:grid-cols-2",
        "lg:grid-cols-1",
        "bg-neutral-100",
        "p-4"
      )}
    >
      <div
        className={clsx(
          "rounded-full aspect-square overflow-hidden mx-auto",
          "self-center w-3/5"
        )}
      >
        <StaticImage
          src="../images/nathalie02.jpg"
          alt="Nathalie Johansson"
          layout="constrained"
          placeholder="blurred"
          aspectRatio={1 / 1}
          className="hover:scale-105 transition-all ease-in"
        />
      </div>
      <div className="lg:w-3/5 mx-auto text-lg self-center">
        <p className="font-condensed font-black uppercase text-4xl mb-4">
          Hej!
        </p>
        <p className="font-thin">
          Jag heter{" "}
          <h1 className="font-normal inline text-neutral-900">
            Nathalie Johansson
          </h1>{" "}
          och är en
          <span className="italic">&nbsp;"amazing organizing woman"</span>!
        </p>
      </div>
    </article>
  );
}
