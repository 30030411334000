import clsx from "clsx";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { propTypes } from "gatsby-plugin-image/dist/src/components/gatsby-image.server";
import * as React from "react";
import { ImageCard, ImageCardProps } from "../components/ImageCard";
import Layout from "../components/Layout";
import { PresentationCard } from "./../components/PresentationCard";

const BG_COLORS = [
  "bg-neutral-50",
  "bg-neutral-100",
  "bg-neutral-200",
  "bg-neutral-300",
  "bg-neutral-400",
  "bg-neutral-500",
  "bg-neutral-600",
  "bg-neutral-700",
  "bg-neutral-800",
  "bg-neutral-900",
];

// markup
export default function Startpage() {
  const data = useStaticQuery<Queries.getPortfolioPagesAndImagesQuery>(graphql`
    query getPortfolioPagesAndImages {
      portfolioPages: allPortfolioPage {
        nodes {
          id
          slug
          title
          headerImage
          categories
        }
      }
      images: allFile(filter: { extension: { in: ["png", "jpg"] } }) {
        nodes {
          relativePath
          relativeDirectory
          id
          publicURL
          sourceInstanceName
          name
          filename: base
          squareImage: childImageSharp {
            id
            gatsbyImageData(
              placeholder: BLURRED
              aspectRatio: 1
              width: 760
              layout: CONSTRAINED
            )
          }
          wideImage: childImageSharp {
            id
            gatsbyImageData(
              placeholder: BLURRED
              aspectRatio: 2
              width: 760
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  `);

  const portfolioPages = data.portfolioPages.nodes;
  const images = data.images.nodes;
  const gridItems = [...portfolioPages, ...portfolioPages];

  return (
    <Layout>
      <ul
        className={clsx(
          "grid",
          "grid-cols-1",
          "sm:grid-cols-2",
          "md:grid-cols-3",
          "lg:grid-cols-4",
          "auto-rows-fr",
          "p-4"
        )}
      >
        <li
          className={clsx(
            "aspect-square",
            "md:aspect-auto md:col-span-full",
            "lg:aspect-square lg:col-span-2 lg:row-span-2"
          )}
        >
          <a href="/om-mig">
            <PresentationCard />
          </a>
        </li>

        {gridItems.map((item, index) => {
          const shouldBeWide =
            (index + 1 !== gridItems.length && (index + 1) % 4 === 0) ||
            index === 0;

          const headerImage = images.find(
            (image) => image.filename === item.headerImage
          );

          const imageData = shouldBeWide
            ? headerImage?.wideImage?.gatsbyImageData
            : headerImage?.squareImage?.gatsbyImageData;

          return (
            <li
              key={`grid-item-${index}`}
              className={clsx(
                "aspect-square",
                shouldBeWide && "md:aspect-auto md:col-span-2"
              )}
            >
              <GridItem id={index}>
                <a href={`/${item.slug}`}>
                  {imageData &&
                    headerImage?.name &&
                    item.title &&
                    item.categories && (
                      <ImageCard
                        image={imageData}
                        alt={headerImage.name}
                        heading={item.title}
                        categories={item.categories.join(" · ")}
                      />
                    )}
                </a>
              </GridItem>
            </li>
          );
        })}
      </ul>
    </Layout>
  );
}

function GridItem({
  id,
  className,
  children,
}: {
  id: number;
  className?: string;
  children: JSX.Element;
}) {
  return (
    <div
      className={clsx(
        BG_COLORS[id],
        "w-full",
        "h-full",
        "grid place-content-center",
        className
      )}
    >
      {children}
    </div>
  );
}
